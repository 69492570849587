import React from "react";
import "./app.css";

export default () => {
  return (
    <main className="container d-flex justify-content-center align-items-center">
      <div className="card text-center">
        <h1 className="h2 m-0">Tommy Klemets</h1>
        <h2 className="h4 mb-3">leg. massör</h2>
        <p>
          boka tid: <a href="tel:+358 50 562 1699">+358 50 562 1699</a>
          <br />
          adress:{" "}
          <a href="https://goo.gl/maps/yyh7ZiCvrrMg3zzi8">
            Karperövägen 553, Karperö
          </a>
        </p>
      </div>
    </main>
  );
};
